import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { navigate } from 'gatsby-link';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

import ChevronRight from '@material-ui/icons/ChevronRight';
import LocationOn from '@material-ui/icons/LocationOn';

import ShiftButtonCard from './ShiftButtonCard';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';
import JobShiftDialog from './JobShiftDialog';

const LocationDetailsWrapper = styled.div`
  flex: 1;
`;
const LocationTitle = styled.p`
  font-size: ${fontSize.large};
  font-weight: 600;
  margin: 0;
`;
const ShiftText = styled.p`
  font-size: ${fontSize.medium};
  margin: 0;
  margin-top: 0.25rem;
`;
const LocationCard = styled(Card)`
  && {
    padding: ${({ isMobile }) => (isMobile ? '0' : '0.5rem')};
    border: ${({ isMobile }) =>
      isMobile ? `1px solid ${colors.grey}` : 'none'};
  }
`;
const MobileWrapper = styled(CardActionArea)`
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;
const DesktopWrapper = styled.div`
  padding-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;
const ShowAllButton = styled(Button)`
  && {
    display: block;
    color: ${colors.primary};
    text-transform: none;
    margin: 0 auto;
    margin-bottom: 0.5rem;
  }
`;
const ShiftList = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const LocationDetailsCard = props => {
  const { location, totalJobs, shiftDetails, shifts, isMobile } = props;

  const [isShowingMoreDialog, setIsShowingMoreDialog] = useState(false);

  return (
    <>
      <JobShiftDialog
        isOpen={isShowingMoreDialog}
        location={location}
        totalJobs={totalJobs}
        shiftDetails={shiftDetails}
        shifts={shifts}
        handleClose={() => setIsShowingMoreDialog(false)}
        isMobile={isMobile}
      />
      <LocationCard elevation={isMobile ? 0 : 2} isMobile={isMobile}>
        {isMobile ? (
          <MobileWrapper onClick={() => setIsShowingMoreDialog(true)}>
            <LocationOn style={{ alignSelf: 'flex-start' }} />
            <LocationDetailsWrapper>
              <LocationTitle>
                {location} ({totalJobs})
              </LocationTitle>
              <ShiftText>{shiftDetails}</ShiftText>
            </LocationDetailsWrapper>
            <ChevronRight />
          </MobileWrapper>
        ) : (
          <>
            <DesktopWrapper>
              <LocationOn style={{ alignSelf: 'flex-start' }} />
              <LocationDetailsWrapper>
                <LocationTitle>
                  {location} ({totalJobs})
                </LocationTitle>
                <ShiftText>Shifts from {shiftDetails}</ShiftText>
              </LocationDetailsWrapper>
            </DesktopWrapper>
            <ShiftList>
              {shifts.slice(0, 3).map(shift => {
                return (
                  <ShiftButtonCard
                    date={shift.shifts}
                    totalJobs={shift.count}
                    onClick={() =>
                      navigate(`/jobs/hourly-gig/${shift.public_id}`)
                    }
                  />
                );
              })}
            </ShiftList>
            {shifts.length > 3 && (
              <ShowAllButton onClick={() => setIsShowingMoreDialog(true)}>
                Show All Jobs
              </ShowAllButton>
            )}
          </>
        )}
      </LocationCard>
    </>
  );
};

export default LocationDetailsCard;
