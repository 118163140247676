/* eslint-disable */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { navigate } from 'gatsby';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import ChevronRight from '@material-ui/icons/ArrowForward';
import ChevronLeft from '@material-ui/icons/ArrowBack';

import JobCard from './JobCard';
import HiringPostCard from './HiringPostCard';
import Slider from '../LandingPage/Slider';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

import { getSimilarJobs } from '../../services/axios';
import { makeUrlGoogleCompliant } from '../../services/helpers';

const sliderOptions = {
  wrapAround: false,
  freeScroll: false,
  fullscreen: true,
  adaptiveHeight: true,
  draggable: true,
  pageDots: true,
  contain: true,
  prevNextButtons: false,
  cellAlign: 'left'
};

const StyledCard = styled(Card)`
  && {
    box-shadow: none;

    @media (min-width: 500px) {
      box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    }
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;

  @media (min-width: 500px) {
    justify-content: space-between;
  }
`;
const Title = styled.div`
  font-size: ${fontSize.xLarge};
  font-weight: bold;
`;
const CarouselContainer = styled.div`
  padding-bottom: 2.5rem;

  @media (min-width: 500px) {
    padding-bottom: 1rem;
  }

  .flickity-page-dots .dot {
    background: ${colors.primary};
    width: 7.5px;
    height: 7.5px;

    @media (min-width: 500px) {
      display: none;
    }
  }
`;
const CardWrapper = styled.div`
  padding: 0 1rem;
`;
const ButtonWrapper = styled.div`
  display: none;

  @media (min-width: 500px) {
    display: flex;
    align-items: center;
  }
`;
const SeeMoreButton = styled(Button)`
  && {
    border: 1px solid ${colors.primary};
    text-transform: none;
    color: ${colors.primary};
    margin-right: ${({ marginRight }) => marginRight && '1rem'};
  }
`;
const PrevNextButtons = styled.div`
  display: none;
  width: 75px;

  @media (min-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
const StyledButton = styled(IconButton)`
  && {
    color: ${colors.primary};
    margin: 0 0.25rem;
  }
`;
const ButtonFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;

  @media (min-width: 500px) {
    display: none;
  }
`;
const Spacer = styled(Divider)`
  && {
    height: 7px;
    background-color: ${({ transparent }) =>
      transparent ? 'transparent' : 'rgba(0, 0, 0, 0.06)'};
    margin: 0.5rem 0;

    @media (min-width: 500px) {
      background-color: transparent;
    }
  }
`;

const PREVIOUS = 'previous';
const NEXT = 'next';
const ONE_JOB = 1;
const IS_SEE_MORE_ENABLED = true;
const GIG_JOB_TYPE = 'Gig';

const MoreJobsCarousel = props => {
  const { title, jobId, sliderName, type } = props;
  const [jobs, setJobs] = useState(null);

  useEffect(() => {
    setJobs(null);
    const fetchJobs = async () => {
      const response = await getSimilarJobs(type, jobId);

      if (response?.isSuccess) {
        setJobs(response?.jobs);
      }
    };

    fetchJobs();
  }, [jobId, type]);

  if (!jobs || jobs?.length < ONE_JOB) return <></>;

  const createCards = () => {
    return jobs?.map(job => {
      return (
        <CardWrapper key={job?.id}>
          {type === GIG_JOB_TYPE ? (
            <JobCard
              tasks={job?.shifts}
              helperType={job?.helper_type}
              fee={job?.fee}
              publicId={job?.public_id}
              postedAt={job?.posted_at}
              posterName={job?.poster?.name}
              location={job?.location}
              timing={job?.timing}
              avatar={job?.poster?.avatar}
              isCard
            />
          ) : (
            <HiringPostCard
              helperType={job?.helper_type}
              employmentType={job?.employment_type}
              title={job?.title}
              avatar={job?.poster?.avatar}
              posterName={job?.poster?.name}
              postedAt={job?.posted_at}
              publicId={job?.public_id}
              schedule={job?.timing}
              salary={job?.fee}
              location={job?.location}
              isCard
            />
          )}
        </CardWrapper>
      );
    });
  };

  const handleClick = direction => {
    if (!direction || !sliderName) return;

    if (direction === NEXT) {
      window[sliderName]?.next();
    } else {
      window[sliderName]?.previous();
    }
  };

  const handleClickSeeMore = () => {
    navigate(makeUrlGoogleCompliant(`/jobs/${jobs && jobs[0]?.helper_type}`));
  };

  return (
    <>
      <Spacer />
      <StyledCard>
        <CardContent>
          <Header>
            <Title>{title}</Title>
            <ButtonWrapper>
              {IS_SEE_MORE_ENABLED && (
                <SeeMoreButton onClick={handleClickSeeMore} marginRight>
                  See More
                </SeeMoreButton>
              )}
              {jobs?.length > ONE_JOB && (
                <PrevNextButtons>
                  <StyledButton
                    onClick={() => handleClick(PREVIOUS)}
                    size="small"
                  >
                    <ChevronLeft />
                  </StyledButton>
                  <StyledButton onClick={() => handleClick(NEXT)} size="small">
                    <ChevronRight />
                  </StyledButton>
                </PrevNextButtons>
              )}
            </ButtonWrapper>
          </Header>
        </CardContent>
        <CarouselContainer>
          <Slider options={sliderOptions} sliderName={sliderName}>
            {createCards()}
          </Slider>
        </CarouselContainer>
        {IS_SEE_MORE_ENABLED && (
          <ButtonFooter>
            <SeeMoreButton onClick={handleClickSeeMore}>See More</SeeMoreButton>
          </ButtonFooter>
        )}
      </StyledCard>
    </>
  );
};

export default MoreJobsCarousel;
