import React from 'react';
import styled from 'styled-components/macro';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import OpenInNew from '@material-ui/icons/OpenInNew';

import GoGetBlack from '../../assets/img/black_logo.png';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const StyledCard = styled(Card)`
  && {
    background-color: transparent;
    box-shadow: none;

    @media (min-width: 500px) {
      background-color: ${colors.greyLight};
      box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    }
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
`;
const Content = styled.div`
  margin: 1rem 0;
  padding: 0.5rem;
`;
const Logo = styled.img`
  height: 60px;
`;
const VisitWebsiteLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${colors.primary};

  @media (max-width: 500px) {
    display: none;
  }
`;
const ButtonWrap = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  @media (max-width: 500px) {
    display: flex;
  }
`;
const VisitWebsiteButton = styled(Button)`
  && {
    border-color: ${colors.primary};
    color: ${colors.primary};
    text-transform: none;
  }
`;
const LinkText = styled.div`
  font-size: ${fontSize.large};
  font-weight: 500;
  margin-right: 0.5rem;
`;
const StyledOpenInNew = styled(OpenInNew)`
  && {
    font-size: 18px;
  }
`;
const Subtitle = styled.h4`
  font-size: ${fontSize.large};
  font-weight: 500;
  margin: 0;
  margin-top: ${({ marginTop }) => marginTop && '1.5rem'};
`;
const Text = styled.p`
  font-size: ${fontSize.regular};
  margin: 0;
  margin-top: 0.5rem;
  line-height: 22px;
`;
const List = styled.ul`
  font-size: ${fontSize.regular};
`;

const LEARN_MORE_LINK = '/gogetters';

const AboutGoGet = () => {
  return (
    <StyledCard>
      <CardContent>
        <Header>
          <Logo src={GoGetBlack} alt="GoGet" />
          <VisitWebsiteLink href={LEARN_MORE_LINK} target="_blank">
            <LinkText>Visit Website</LinkText>
            <StyledOpenInNew />
          </VisitWebsiteLink>
        </Header>
        <Content>
          <Subtitle>About GoGet</Subtitle>
          <Text>
            GoGet is a community platform for reliable and skilled manpower. We
            connect you to earning opportunities, through a variety of jobs
            posted by users who seek help called 'Posters'.
          </Text>
          <Subtitle marginTop>Who are GoGetters?</Subtitle>
          <Text>
            GoGetters are skilled individuals that have been verified by GoGet.
            Find work when you want, learn new skills, network with our
            community and stay protected.
          </Text>
          <Subtitle marginTop>What is required to become a GoGetter?</Subtitle>
          <List>
            <li>18 years old and above</li>
            <li>Online banking account</li>
            <li>Smartphone with data connection</li>
            <li>Good command of English</li>
          </List>
          <ButtonWrap>
            <VisitWebsiteButton
              href={LEARN_MORE_LINK}
              target="_blank"
              variant="outlined"
              endIcon={<StyledOpenInNew />}
              size="large"
            >
              Learn more on our website
            </VisitWebsiteButton>
          </ButtonWrap>
        </Content>
      </CardContent>
    </StyledCard>
  );
};

export default AboutGoGet;
