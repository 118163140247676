import React from 'react';
import styled from 'styled-components/macro';
import { navigate } from 'gatsby-link';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';

import Close from '@material-ui/icons/Close';
import LocationOn from '@material-ui/icons/LocationOn';

import ShiftButtonCard from './ShiftButtonCard';

import fontSize from '../../assets/fontSize';

const TitleContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'row-reverse' : 'row')};
  justify-content: ${({ isMobile }) =>
    isMobile ? 'flex-end' : 'space-between'};
  gap: 1rem;
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
`;
const Title = styled.p`
  font-size: ${fontSize.xLarge};
  font-weight: 600;
  margin: 0;
`;
const ShiftList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
`;
const LocationDetails = styled.div`
  padding-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;
const LocationDetailsWrapper = styled.div`
  flex: 1;
`;
const LocationTitle = styled.p`
  font-size: ${fontSize.large};
  font-weight: 600;
  margin: 0;
`;
const ShiftText = styled.p`
  font-size: ${fontSize.medium};
  margin: 0;
  margin-top: 0.25rem;
`;

const JobShiftDialog = props => {
  const {
    location,
    totalJobs,
    shiftDetails,
    shifts,
    isOpen,
    handleClose,
    isMobile
  } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullScreen={isMobile}
      maxWidth="xs"
      fullWidth
      scroll="paper"
    >
      <TitleContent isMobile={isMobile}>
        <Title>Select Timing</Title>
        <IconButton size="small" onClick={handleClose}>
          <Close />
        </IconButton>
      </TitleContent>
      <DialogContent dividers>
        <LocationDetails>
          <LocationOn style={{ alignSelf: 'flex-start' }} />
          <LocationDetailsWrapper>
            <LocationTitle>
              {location}, ({totalJobs})
            </LocationTitle>
            <ShiftText>Shifts from {shiftDetails}</ShiftText>
          </LocationDetailsWrapper>
        </LocationDetails>
        <ShiftList>
          {shifts?.map(shift => {
            return (
              <ShiftButtonCard
                date={shift.shifts}
                totalJobs={shift.count}
                onClick={() => navigate(`/jobs/hourly-gig/${shift.public_id}`)}
              />
            );
          })}
        </ShiftList>
      </DialogContent>
    </Dialog>
  );
};

export default JobShiftDialog;
