import React from 'react';
import styled from 'styled-components/macro';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

import AccessTime from '@material-ui/icons/AccessTime';
import ChevronRight from '@material-ui/icons/ChevronRight';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const ShiftCard = styled(Card)`
  && {
    border: 1px solid ${colors.grey};
  }
`;
const CardFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
  gap: 1rem;
`;
const ShiftRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
const ShiftTime = styled.p`
  font-size: ${fontSize.large};
  margin: 0;
`;
const JobChip = styled.div`
  background-color: ${colors.greyLight};
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: ${fontSize.medium};
  display: flex;
  align-items: center;
  justify-content: center;
  width: min-content;
  white-space: nowrap;
  margin-top: 0.75rem;
`;
const ShiftFlex = styled.div`
  flex: 1;
`;

const ShiftButtonCard = props => {
  const { date, totalJobs, onClick } = props;
  return (
    <ShiftCard elevation={0}>
      <CardActionArea onClick={onClick}>
        <CardFlex>
          <ShiftFlex>
            <ShiftRow>
              <AccessTime fontSize="small" />
              <ShiftTime>{date}</ShiftTime>
            </ShiftRow>
            <JobChip>
              {totalJobs} job{totalJobs === 1 ? '' : 's'} available
            </JobChip>
          </ShiftFlex>
          <ChevronRight />
        </CardFlex>
      </CardActionArea>
    </ShiftCard>
  );
};

export default ShiftButtonCard;
