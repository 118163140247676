import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import LocationDetailsCard from './LocationDetailsCard';

import { getPosterShifts } from '../../services/axios';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const MainCard = styled(Card)`
  background-color: ${colors.appleGreen};
`;
const Title = styled.p`
  font-size: ${fontSize.large};
  font-weight: 600;
  text-align: center;
  margin: 0;
`;
const LocationList = styled.div`
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const NoShiftsAvailable = styled.p`
  padding: 1rem;
  font-size: ${fontSize.large};
  font-weight: 600;
  text-align: center;
`;
const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;
const Loader = styled(CircularProgress)`
  && {
    svg {
      color: ${colors.primary};
    }
  }
`;
const LoadingText = styled.p`
  font-size: ${fontSize.large};
  font-weight: 600;
  color: ${colors.primary};
`;

const JobShiftModalV2 = props => {
  const { posterId, helperType, location, isMobile } = props;
  const [jobShifts, setJobShifts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPosterShifts = async () => {
      const response = await getPosterShifts(posterId, helperType);

      if (response?.isSuccess) {
        setJobShifts(response?.shifts || []);
      }
      setIsLoading(false);
    };

    if (posterId && helperType) {
      fetchPosterShifts();
    }
  }, [posterId, helperType, location]);

  const totalJobs = jobShifts?.reduce((a, b) => a + b.total, 0);

  return (
    <MainCard>
      <CardContent>
        <Title>
          {totalJobs} job{totalJobs === 1 ? '' : 's'} available in{' '}
          {jobShifts?.length} location
          {jobShifts?.length === 1 ? '' : 's'}
        </Title>
        <LocationList>
          {isLoading ? (
            <LoadingContainer>
              <Fab
                size="small"
                style={{ backgroundColor: colors.white, cursor: 'default' }}
                disableRipple
              >
                <Loader size={24} />
              </Fab>
              <LoadingText>Loading jobs...</LoadingText>
            </LoadingContainer>
          ) : (
            <>
              {jobShifts.length > 0 ? (
                <>
                  {jobShifts.map(shift => {
                    return (
                      <LocationDetailsCard
                        key={`${shift.area}, ${shift.state}`}
                        location={`${shift.area}, ${shift.state}`}
                        shifts={shift.shifts}
                        shiftDetails={shift.date_range}
                        totalJobs={shift.total}
                        isMobile={isMobile}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  <Card>
                    <NoShiftsAvailable>No shifts available</NoShiftsAvailable>
                  </Card>
                </>
              )}
            </>
          )}
        </LocationList>
      </CardContent>
    </MainCard>
  );
};

export default JobShiftModalV2;
